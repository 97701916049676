<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
			资产信息不完善，通过批量修改完善资产信息
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from './Common/Table.vue'
export default {
    components:{
		TableVue
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'AssetBatch/index',
				add:'AssetBatch/add',
				edit:'AssetBatch/edit',
				del:'AssetBatch/del',
				export:'/AssetBatch/export'
			} 
			this.$refs.c_table.cardName =  '批量修改单'
			this.$refs.c_table.storageType =  10
			this.$refs.c_table.expandStatus = true
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>